
export const formFields1 = [
  { label: "Référence"},
  { label: "Désignation"},
  { label: "Classe energétique" },
  { label: "Dimension" },
  { label: "Garantie" },
  { label: "Vitesse d'essorage" },
  { label: "Nombre de programme" },
  { label: "Consommation KWH/AN" },
  { label: "Technologie" },
  { label: "Capacité de tambour" },
  { label: "Prix au comptant" },
  { label: "12 mois" },
  { label: "24 mois" },
  { label: "36 mois" },
];


export const formFields2 = [
  { label: "Référence"},
  { label: "Déscription"},
  { label: "Dimension" },
  { label: "Champ 1" },
  { label: "Champ 2" },
  { label: "Champ 3" },
  { label: "Champ 4" },
  { label: "Champ 5" },
  { label: "Couleur" },
  { label: "Prix au comptant" },
  { label: "12 mois" },
  { label: "24 mois" },
  { label: "36 mois" },
];



export const formFields3 = [
  { label: "Référence"},
  { label: "Désignation" },
  { label: "Classe energétique" },
  { label: "Dimension" },
  { label: "Garantie" },
  { label: "Volume congélateur" },
  { label: "Volume réfrigérateur" },
  { label: "Consommation KWH/AN" },
  { label: "Nombre des étagères" },
  { label: "Système de refroidissement", fields:[{label: "No-Frost"}, {label: "D-Frost"}]},
  { label: "Prix au comptant" },
  { label: "12 mois" },
  { label: "24 mois" },
  { label: "36 mois" },
];

export const formFields4 = [
  { label: "Prix au comptant 1", name:"Prix au comptant",
  fields: [{label:"12 mois 1", name:"12 mois"},{label:"24 mois 1", name:"24 mois"},{label:"36 mois 1", name:"36 mois"},
    {label:"Référence 1", name:"Référence"},{label:"Description 1", name:"Description"}]},
  { label: "Prix au comptant 2", name:"Prix au comptant",
  fields: [{label:"12 mois 2", name:"12 mois"},{label:"24 mois 2", name:"24 mois"},{label:"36 mois 2", name:"36 mois"},
    {label:"Référence 2", name:"Référence"},{label:"Description 2", name:"Description"}]},
  { label: "Prix au comptant 3", name:"Prix au comptant",
  fields: [{label:"12 mois 3", name:"12 mois"},{label:"24 mois 3", name:"24 mois"},{label:"36 mois 3", name:"36 mois"},
  {label:"Référence 3", name:"Référence"},{label:"Description 3", name:"Description"}]},
   { label: "Prix au comptant 4", name:"Prix au comptant",
   fields: [{label:"12 mois 4", name:"12 mois"},{label:"24 mois 4", name:"24 mois"},{label:"36 mois 4", name:"36 mois"},
   {label:"Référence 4", name:"Référence"},{label:"Description 4", name:"Description"}]},
];

export const formFields5 = [
  { label: "Prix au comptant 1", name:"Prix au comptant",
  fields: [{label:"Référence 1", name:"Référence"},{label:"Description 1", name:"Description"}]},
  { label: "Prix au comptant 2", name:"Prix au comptant",
  fields: [{label:"Référence 2", name:"Référence"},{label:"Description 2", name:"Description"}]},
  { label: "Prix au comptant 3", name:"Prix au comptant",
  fields: [{label:"Référence 3", name:"Référence"},{label:"Description 3", name:"Description"}]},
   { label: "Prix au comptant 4", name:"Prix au comptant",
   fields: [{label:"Référence 4", name:"Référence"},{label:"Description 4", name:"Description"}]},
   { label: "Prix au comptant 5", name:"Prix au comptant",
   fields: [{label:"Référence 5", name:"Référence"},{label:"Description 5", name:"Description"}]},
   { label: "Prix au comptant 6", name:"Prix au comptant",
   fields: [{label:"Référence 6", name:"Référence"},{label:"Description 6", name:"Description"}]},
];


export const formFields6 = [
  { label: "Référence"},
  { label: "Désignation" },
  { label: "Écran" },
  { label: "HDMI" },
  { label: "USB" },
  { label: "Résolution" },
  { label: "Système d'exploitation" },
  { label: "Garantie" },
  { label: "Récepteur intégrer" },
  { label: "Smart", fields:[{label: "Oui"}, {label: "Non"}]},
  { label: "Prix au comptant" },
  { label: "12 mois" },
  { label: "24 mois" },
  { label: "36 mois" },
];
export const formFields7 = [
  { label: "Nombre de mois", name:"Nombre de mois"},
];


// export const formFields4 = [
//   { label: "Prix au comptant 1"},
//   { label: "Référence 1"},
//   { label: "Description 1"},
//   { label: "12 mois 1"},
//   { label: "24 mois 1"},
//   { label: "36 mois 1"},
//   { label: "Prix au comptant 2"},
//   { label: "Référence 2"},
//   { label: "Description 2"},
//   { label: "12 mois 2"},
//   { label: "24 mois 2"},
//   { label: "36 mois 2"},
//   { label: "Prix au comptant 3"},
//   { label: "Référence 3"},
//   { label: "Description 3"},
//   { label: "12 mois 3"},
//   { label: "24 mois 3"},
//   { label: "36 mois 3"},
//   { label: "Prix au comptant 4"},
//   { label: "Référence 4"},
//   { label: "Description 4"},
//   { label: "12 mois 4"},
//   { label: "24 mois 4"},
//   { label: "36 mois 4"},
// ];
