import React from 'react';

// import components

import Home from'./components/Home';


const App = () => {
  return (
    <div className='bg-white relative'>
     <Home /> 
    </div>
  );
};

export default App;
