import React, { useState, forwardRef, useImperativeHandle } from "react";
import { formFields2 } from '../data';
import { isValidData } from './Validation';
import file from '../assets/files/ticket4.pdf';



const TicketMal = forwardRef((props, ref) => {
  const [fields, setFields] = useState(formFields2);
  const [errors, setErrors] = useState([]);

  useImperativeHandle(ref, () => ({
    validateFields() {
      const newErrors = isValidData(fields, null, null, file);
      setErrors(newErrors);
      return newErrors.length === 0;
    }
  }));

  const handleChange = (index, value) => {
    setFields(fields.map((field, i) => {
      if (index === i) {
        return { ...field, value };
      } else {
        return field;
      }
    }));
  };

  return (
    <>
    <div class="bg-slate-50 rounded shadow p-8">
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 mt-5">
      {fields.slice(0, -4).map((field, index) => (
        <div key={index}>
          <label className="block mb-1 text-black text-sm text-left">{field.label}</label>
          <input
            className="w-full bg-gray-200 text-black text-sm mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"       
            value={field.value}
            onChange={(e) => handleChange( index, e.target.value)} />
          {errors.includes(field.validator) && <span className="text-red-500 text-sm block text-left">{field.validator}</span>}
        </div>
      ))}
    </div>
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 mt-5">
      {fields.slice(9,10).map((field, index) => (
        <div key={index}>
          <label className="block mb-1 text-black text-sm text-left">{field.label}</label>
          <input
            className="w-full bg-gray-200 text-black text-sm mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"       
            value={field.value}
            onChange={(e) => handleChange( 9, e.target.value)} />
          {errors.includes(field.validator) && <span className="text-red-500 text-sm block text-left">{field.validator}</span>}
        </div>
      ))}
    </div>
    <div className="grid grid-cols-1 gap-8 md:grid-cols-3 mt-5">
        {fields.slice(-3).map((field, index) => (
          <div key={index}>
            <label className="block mb-1 text-black text-sm text-left">{field.label}</label>
            <input
              className="w-full bg-gray-200 text-black text-sm mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              type="text"              
               value={field.value}
              onChange={(e) => handleChange(fields.length - 3 + index, e.target.value)} />
            {errors.includes(field.validator) && <span className="text-red-500 text-sm block text-left">{field.validator}</span>}
          </div>
        ))}
      </div>
      </div>
      </>
   );
  });

export default TicketMal;
