import React, {  useState, useRef } from 'react';
import TicketMeuble from './TicketMeuble.js';
import TicketMal from './TicketMal.js';
import TicketREF from './TicketREF.js';
import TicketReste from'./TicketReste.js';
import TicketPrixMiniature from './TicketPrixMiniature.js';
import TicketprixTV from './TicketprixTV.js';
import TicketOffre from './TicketOffre.js';
import image from '../assets/img/logo-graiet.png'
import Logo from '../assets/img/graiet.png'

const Home = () => {
  const [activeComponent, setActiveComponent] = useState("TicketMal");

  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  const childRef = useRef();

  const validateChildProperties = () => {
    childRef.current.validateFields();
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "TicketMal":
        return <TicketMal ref={childRef}/>;
      case "TicketMeuble":
        return <TicketMeuble ref={childRef}/>;
      case "TicketREF":
        return <TicketREF ref={childRef}/>;
      case "TicketReste":
        return <TicketReste ref={childRef}/>;
      case "TicketPrixMiniature":
        return <TicketPrixMiniature ref={childRef}/>;
      case "TicketprixTV":
        return <TicketprixTV ref={childRef}/>;
      case "TicketOffre":
        return <TicketOffre  ref={childRef}/>;
    }
  };

  const buttonClass =
    "text-xs font-semibold inline-flex items-center justify-center w-1/2 py-3 font-medium leading-none tracking-wider border-b-2 sm:px-6 sm:w-1/5 sm:justify-start title-font";

  const activeButtonClass =
    " border-indigo-500 text-indigo-500 bg-gray-100 rounded-t";

  const inactiveButtonClass =
    "border-gray-200 hover:text-gray-900";

  return (
    <div>
      <div className="m-8">
      <div className="flex flex-col justify-center items-center">
     <img src={Logo} alt="image description" className="w-72 h-auto" />
    </div>
 
        <h2 className="mb-4 text-xl font-bold text-center text-gray-800 lg:text-3xl md:mb-6">
        Formulaire des tickets
        </h2>

        <p className="max-w-screen-md text-sm mx-auto text-center text-gray-500 md:text-sm">
          Veuillez s'il vous plaît remplir les informations suivantes.
        </p>
      </div>

      <div className="text-gray-600">
        <div className=" flex flex-col flex-wrap px-5 py-4 mx-auto">
          <div className="flex flex-wrap sm:flex-nowrap mx-auto">
            <button
              className={`${buttonClass} ${
                activeComponent === "TicketMal"
                  ? activeButtonClass
                  : inactiveButtonClass
              } transition-all duration-300 ease-in-out`}
              onClick={() => handleButtonClick("TicketMal")}
            >
              ticket de prix MAL
            </button>

            <button
              className={`${buttonClass} ${
                activeComponent === "TicketMeuble"
                  ? activeButtonClass
                  : inactiveButtonClass
              } transition-all duration-300 ease-in-out`}
              onClick={() => handleButtonClick("TicketMeuble")}
            >
              ticket de prix Meuble
            </button>

            <button
              className={`${buttonClass} ${
                activeComponent === "TicketREF"
                  ? activeButtonClass
                  : inactiveButtonClass
              } transition-all duration-300 ease-in-out`}
              onClick={() => handleButtonClick("TicketREF")}
            >
              ticket de prix REF R
            </button>

            <button
              className={`${buttonClass} ${
                activeComponent === "TicketReste"
                  ? activeButtonClass
                  : inactiveButtonClass
              } transition-all duration-300 ease-in-out`}
              onClick={() => handleButtonClick("TicketReste")}
            >
              RESTE TICKET DE PRIX
            </button>

            <button
              className={`${buttonClass} ${
                activeComponent === "TicketPrixMiniature"
                  ? activeButtonClass
                  : inactiveButtonClass
              } transition-all duration-300 ease-in-out`}
              onClick={() => handleButtonClick("TicketPrixMiniature")}
            >
              Ticket de prix miniature horizontale
            </button>


            <button
              className={`${buttonClass} ${
                activeComponent === "TicketprixTV"
                  ? activeButtonClass
                  : inactiveButtonClass
              } transition-all duration-300 ease-in-out`}
              onClick={() => handleButtonClick("TicketprixTV")}
            >
              Ticket de prix TV
            </button>

            <button
              className={`${buttonClass} ${
                activeComponent === "TicketOffre"
                  ? activeButtonClass
                  : inactiveButtonClass
              } transition-all duration-300 ease-in-out`}
              onClick={() => handleButtonClick("TicketOffre")}
            >
              Ticket Offre Spéciale
            </button>

            
          </div>

          <div className="flex flex-col w-full text-center">
            <div className="py-6 bg-white sm:py-8 lg:py-12">
              <div className="px-4 mx-auto max-w-screen-lg md:px-8 container">
                {renderActiveComponent()}
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4 text-center">
        <button class="text-sm px-8 py-2 font-medium text-white bg-indigo-500 rounded hover:bg-indigo-600 mb-8"
        onClick={() => validateChildProperties()}>
          imprimer
        </button>
        </div>
        
      </div>
    </div>
  );
};

export default Home;
