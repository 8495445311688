import React, { useState, forwardRef, useImperativeHandle } from "react";
import { formFields5 } from '../data';
import { isValidData } from './Validation';
import file from '../assets/files/ticket5.pdf';

const TicketPrixMiniature = forwardRef((props, ref) => {
  const [fields, setFields] = useState(formFields5);
  const [errors, setErrors] = useState([]);

  useImperativeHandle(ref, () => ({
    validateFields() {
      const newErrors = isValidData(fields, null, null, file);
      setErrors(newErrors);
      return newErrors.length === 0;
    }
  }));

  const handleChange = (index, value) => {
    setFields(fields.map((field, i) => {
      if (index === i) {
        return { ...field, value };
      } else {
        return field;
      }
    }));
  };

  const handleSubChange = (index, value, subIndex) => {
    setFields(fields.map((field, i) => {
      if (index === i) {
        if (field.fields) {
          return {
            ...field,
            fields: field.fields.map((subField, j) => {
              if (subIndex === j) {
                return { ...subField, value };
              } else {
                return subField;
              }
            })
          }
        } else {
          return { ...field, value };
        }
      } else {
        return field;
      }
    }));
  };

  return (
    <div >
      {fields.map((field, index) => (
       <div class="container mx-auto p-4">
        <div class="bg-slate-50 rounded shadow p-8">
        <div key={index} className="grid grid-cols-1 gap-3 md:grid-cols-1 mt-5">
          <div>
          <label className="block mb-1 text-black text-sm text-left">{field.name}</label>
          <input
            className="w-full bg-gray-200 text-black text-sm mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
            value={field.value}
            onChange={(e) => handleChange(index, e.target.value)}
          />
          {errors.includes(field.validator) && (
            <span className="text-red-500 text-sm block text-left">{field.validator}</span>
          )}
          </div>
          {field.fields && (
            <><div className="grid grid-cols-1 gap-8 md:grid-cols-2 mt-5">
              {field.fields.slice(-2).map((subField, subIndex) => (
                <div key={subIndex}>
                  <label className="block mb-1 text-black text-sm text-left">
                    {subField.name}
                  </label>
                  <input
                    className="w-full bg-gray-200 text-black text-sm mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="text"
                    value={subField.value}
                    onChange={(e) => handleSubChange(index, e.target.value, subIndex)} />
                  {errors.includes(subField.validator) && (
                    <span className="text-red-500 text-sm block text-left">
                      {subField.validator}
                    </span>
                  )}
                </div>
              ))}
            </div></>
          )}
        </div>
        </div>
        </div>
      ))}
    </div>
  );
});

export default TicketPrixMiniature;
