import React, { useState, forwardRef, useImperativeHandle } from "react";
import { formFields6 } from '../data';
import { isValidData } from './Validation';
import file from '../assets/files/ticket6.pdf';


const TicketprixTV = forwardRef((props, ref) => {
  const [fields, setFields] = useState(formFields6);
  const [Smart, setSmart] = useState('');
  const [errors, setErrors] = useState([]);

  const handleRadioInputChange = (event) => {
    setSmart(event.target.value);
  };

  useImperativeHandle(ref, () => ({
    validateFields() {
      const newErrors = isValidData(fields, null, Smart, file);
      setErrors(newErrors);
      return newErrors.length === 0;
    }
  }));

  const handleChange = (index, value) => {
    setFields(fields.map((field, i) => {
      if (index === i) {
        return { ...field, value };
      } else {
        return field;
      }
    }));
  };

  return (
    <>
    <div class="bg-slate-50 rounded shadow p-8">
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 mt-5">
      {fields.slice(0, -5).map((field, index) => (
        <div key={index}>
          <label className="block mb-1 text-black text-sm text-left">{field.label}</label>
          <input
            className="w-full bg-gray-200 text-black text-sm mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
            value={field.value}
            onChange={(e) => handleChange(index, e.target.value)} />
          {errors.includes(field.validator) && <span className="text-red-500 text-sm block text-left">{field.validator}</span>}
        </div>
      ))}
 
    {fields.slice(-5, -4).map((field, index) => (
       <div>
       <label className="block mb-1 text-black text-sm text-left">{field.label}</label>
       <div className="flex space-x-10 items-center p-3">
         {field.fields.map((subField, subIndex) => (
           <label className="text-black text-sm">
             {subField.label}
             <input
               type="radio"
               name="système-de-refroidissement"
               value={subField.label}
               checked={Smart === subField.label}
               onChange={handleRadioInputChange} />
           </label>
           
         ))}
       </div>
       {errors.includes(field.validator) && <span className="text-red-500 text-sm block text-left">{field.validator}</span>}
     </div>

      ))}
      </div>
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 mt-5">
        {fields.slice(-4,-3).map((field, index) => (
          <div key={index}>
            <label className="block mb-1 text-black text-sm text-left">{field.label}</label>
            <input
            className="w-full bg-gray-200 text-black text-sm mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
            value={field.value}
            onChange={(e) => handleChange(fields.length-4 + index, e.target.value)} />
          {errors.includes(field.validator) && <span className="text-red-500 text-sm block text-left">{field.validator}</span>}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-3 mt-5">
        {fields.slice(-3).map((field, index) => (
          <div key={index}>
            <label className="block mb-1 text-black text-sm text-left">{field.label}</label>
            <input
            className="w-full bg-gray-200 text-black text-sm mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
            value={field.value}
            onChange={(e) => handleChange(fields.length-3 + index, e.target.value)} />
          {errors.includes(field.validator) && <span className="text-red-500 text-sm block text-left">{field.validator}</span>}
          </div>
        ))}
      </div>
      </div>
      </>
  );
});

export default TicketprixTV;