import { PDFDocument } from 'pdf-lib';


export const Form = async (fields, systèmeDeRefroidissement, Smart, fileInput) => {
  const existingPdfBytes = await fetch(fileInput).then((res) =>
    res.arrayBuffer()
  );

  // Load the PDF document
  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  // Get the form fields from the PDF document
  const form = pdfDoc.getForm();
  const Fields = form.getFields();

  const checkBox = ["Système de refroidissement", "Smart"]

  // Map the values of the form fields to the corresponding fields in the PDF form
  fields.forEach((field) => {
    if ( !checkBox.includes(field.label)){
    if (field.fields) {
      form.getField(field.label).setText(field.value);
      field.fields.forEach((nestedField) => {
        form.getField(nestedField.label).setText(nestedField.value);
      });
    }
     else {
      form.getField(field.label).setText(field.value);
    }
  }
    if (systèmeDeRefroidissement == 'D-Frost') {
        form.getRadioGroup('d-frost').select('Value_xkmh');
     }
     else if (systèmeDeRefroidissement == 'No-Frost') {
      form.getRadioGroup('no-frost').select('Value_ubyo');
   }

   if (Smart == 'Oui') {
    form.getRadioGroup('Oui').select('Oui');
 }
 else if (Smart == 'Non') {
  form.getRadioGroup('Non').select('Non');
  }

   if (Fields.some(field => field.getName() === 'Référence_V04I') && (field.label === "Référence")) {
    const référenceFieldValue = field.value;
       const référenceV04IField = form.getField("Référence_V04I");
        if (référenceV04IField) {
          référenceV04IField.setText(référenceFieldValue);
        }
  } 


  });



  

  
  
  

  const pdfBytesWithForm = await pdfDoc.save();

  const blob = new Blob([pdfBytesWithForm], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  // Create a hidden iframe element and set its source to the filled PDF file
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  iframe.src = url;
  document.body.appendChild(iframe);

  // Print the PDF file
  iframe.onload = function () {
    iframe.contentWindow.print();
  };
};






export const isValidData = (fields, systèmeDeRefroidissement = null, Smart = null, fileInput) => {
  const newErrors = [];


  // fields.forEach((field) => {
  //   if (field.label  && field.label !== "Système de refroidissement") {
  //     if (!field.value && !field.fields) {
  //       field.validator = `le champ ${field.label} est obligatoire`;
  //       newErrors.push(field.validator);
  //     }
  //     else if(!field.value && field.fields){
  //       field.validator = `le champ ${field.name} est obligatoire`;
  //       newErrors.push(field.validator);
  //     }
  //     else {
  //       field.validator = '';
  //     }
  //    }
  //   if (field.fields ) {
  //     field.fields.forEach((nestedField) => {
  //       if (!nestedField.value && nestedField.label !== "No-Frost" && nestedField.label !== "D-Frost") {
  //         nestedField.validator = `le champ ${nestedField.name} est obligatoire`;
  //        newErrors.push(nestedField.validator);
  //      } else  {
  //        nestedField.validator = '';
  //      }
  //     });
  //   }
  //    if (!systèmeDeRefroidissement && field.label === "Système de refroidissement") {
  //      field.validator = `le champ ${field.label} est obligatoire`;
  //      newErrors.push(field.validator);
  //    }
  //  });

  console.log(newErrors)

  if (newErrors.length === 0) {
    Form(fields,systèmeDeRefroidissement,Smart,fileInput);
  }

  return newErrors;
};

